.art-container {
	height: 100%;
	text-align: center;
	margin: 20px 20% 50px 20%;
}

.preview-container {
	height: 250px;
	width: 250px;
	margin: 25px 25px 25px 25px;
}